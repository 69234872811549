:root {
  --s-textcolor: #232935;
  --s-sitecolor: #0AD705;
  --s-linkcolor: #232935;
  --s-linkcolor-hov: #0AD705;
  --s-btn-border: #232935;
  --s-btn-bg: #F4F4F4;
  --s-btn-bg-hov: #232935;
  --s-btn-txt: #232935;
  --s-btn-txt-hov: #F4F4F4;
  --s-getintouch-icon: url(/images/icon-mail-greyblue.svg);
  --s-getintouch-icon-hov: url(/images/icon-mail-green.svg);
}
:root {
  --deskWidth: 1320px;
  --deskSpace: 40px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 1279px) {
  :root {
    --deskSpace: 8vw;
  }
}
:root {
  --spacePart: 15px;
}
@media (max-width: 1279px) {
  :root {
    --spacePart: 15px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 25px;
}
@media (max-width: 1279px) {
  :root {
    --spaceUnit: 25px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 15px;
  }
}
:root {
  --spaceTotal: 40px;
}
@media (max-width: 1279px) {
  :root {
    --spaceTotal: 40px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 25px;
  }
}
:root {
  --js-breakpoint: breakpointLarge;
}
@media (max-width: 1279px) {
  :root {
    --js-breakpoint: breakpointMedium;
  }
}
@media (max-width: 767px) {
  :root {
    --js-breakpoint: breakpointSmall;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .button:hover,
  .button:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .button:active {
    background-color: var(--s-btn-bg-hov);
  }
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: var(--s-sitecolor);
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: bold;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 20px;
  font-family: 'brandon-grotesque', sans-serif;
  line-height: 1.3;
}
.unit caption {
  display: none;
}
.flag {
  background: var(--s-sitecolor);
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit__background {
  float: left;
  width: 100%;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
#edit .part video {
  pointer-events: none;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  padding-left: 90px;
  min-height: 79px;
  background-size: 66px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url(/images/icon-download.svg);
  font-size: 24px;
  font-weight: bold;
}
@media (max-width: 767px) {
  div.load a.load {
    font-size: 20px;
    padding-left: 70px;
    min-height: 55px;
    background-size: 46px;
  }
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid var(--s-textcolor);
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.part.cb-youtube iframe,
.part.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.part.cb-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.part.cb-share .cb-service {
  float: left;
  margin-left: 16px;
}
.part.cb-share .cb-service:first-child {
  margin-left: 0;
}
.part.cb-share .cb-service * {
  float: left;
}
.part.cb-share .cb-service a {
  float: left;
  width: 50px;
  height: 50px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10000px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.part.cb-share .cb-service a:hover {
  filter: invert(1);
}
.part.cb-share .cb-service.cb-service-facebook-share a {
  background-size: auto 24px;
  background-image: url(/images/cb-default/socialShare-facebook-black.svg);
}
.part.cb-share .cb-service.cb-service-twitter a {
  background-size: auto 18px;
  background-image: url(/images/cb-default/socialShare-twitter-black.svg);
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
.cb-multimood,
.cb-expo,
#expo {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
}
.cb-slides,
#slides {
  float: left;
  width: 100%;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;
  isolation: isolate;
}
.cb-slides:before,
#slides:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(35, 41, 53, 0.3);
}
.cb-slides .slide,
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
.cb-slides a,
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
.cb-slides a.null,
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
.cb-slides img,
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: var(--s-sitecolor);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: var(--s-sitecolor);
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'brandon-grotesque', sans-serif;
  line-height: 1.42857143;
  color: var(--s-textcolor);
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: var(--s-sitecolor);
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: var(--s-sitecolor);
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  line-height: 1.42857143;
}
@media (hover: hover) and (pointer: fine) {
  #disp .foot input:hover,
  #disp .foot a:hover,
  #disp .foot input:focus,
  #disp .foot a:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  #disp .foot input:active,
  #disp .foot a:active {
    background-color: var(--s-btn-bg-hov);
  }
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1279px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'brandon-grotesque', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.unit.form .part.must,
.cb-form-required {
  display: none;
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
  font-size: 24px;
  color: rgba(35, 41, 53, 0.5);
  font-weight: bold;
  padding-left: 20px;
  box-sizing: border-box;
}
@media (max-width: 767px) {
  .unit.form .name {
    font-size: 20px;
  }
}
.unit.form .tile .name {
  display: none;
}
@media (min-width: 768px) {
  .unit.form .tile:not(.word) .name {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding-bottom: 0;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #F4F4F4;
  min-height: 56px;
  padding: 5px 10px;
  -webkit-appearance: none;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.form .pile textarea,
.unit.form .pile input.text {
  background: #F4F4F4;
  border: 3px solid var(--s-textcolor);
  box-sizing: border-box;
}
.unit.form .tile input.text {
  padding: 0 10px;
  min-height: 50px;
}
.unit.form .tile input.text::placeholder {
  font-size: 24px;
  color: rgba(35, 41, 53, 0.5);
  font-weight: bold;
}
@media (max-width: 767px) {
  .unit.form .tile input.text::placeholder {
    font-size: 20px;
  }
}
.unit.form .tile fieldset {
  background: #F4F4F4;
  border: 3px solid var(--s-textcolor);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 26px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 15px;
  }
}
.part--choice .ctrl > div:not(.select-editor) {
  padding-left: 20px !important;
  box-sizing: border-box;
}
.part--choice .ctrl > div:not(.select-editor) .checkbox,
.part--choice .ctrl > div:not(.select-editor) .radio {
  left: 20px !important;
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'brandon-grotesque', sans-serif;
  font-size: 20px;
  line-height: 1.3;
  background: #F4F4F4;
  height: 56px;
  border: 3px solid var(--s-textcolor);
  padding: 5px;
}
.unit.form input[type=file]::file-selector-button {
  margin-right: 20px;
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  line-height: 1;
  padding: 12px 20px;
  font-size: 24px;
  color: rgba(35, 41, 53, 0.5);
  border-radius: 27px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  font-family: 'brandon-grotesque', sans-serif;
}
@media (hover: hover) and (pointer: fine) {
  .unit.form input[type=file]::file-selector-button:hover,
  .unit.form input[type=file]::file-selector-button:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .unit.form input[type=file]::file-selector-button:active {
    background-color: var(--s-btn-bg-hov);
  }
}
@media (max-width: 767px) {
  .unit.form input[type=file]::file-selector-button {
    font-size: 20px;
  }
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  width: 100%;
  display: block;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 56px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form .submit {
  float: right;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  font-family: 'brandon-grotesque', sans-serif;
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .unit.form .submit:hover,
  .unit.form .submit:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .unit.form .submit:active {
    background-color: var(--s-btn-bg-hov);
  }
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: var(--s-textcolor);
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .two-step-verification-container a:hover,
  .two-step-verification-container a:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .two-step-verification-container a:active {
    background-color: var(--s-btn-bg-hov);
  }
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: bold;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: bold;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.60756193vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
.footlogo,
.cb-home,
#home {
  float: left;
  height: 38px;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
body.cb-toggle-target-active .footlogo,
body.cb-toggle-target-active .cb-home,
body.cb-toggle-target-active #home {
  content: url('/images/logo-daktiv-neg.svg');
}
@media (max-width: 1279px) {
  .footlogo {
    height: 57px;
    margin-bottom: calc(var(--spaceTotal) * 2);
  }
}
.footlogo__img,
.logo {
  width: auto;
  height: 100%;
}
.cb-page-title,
#head {
  float: left;
  width: 100%;
  margin-top: 160px;
}
@media (max-width: 767px) {
  .cb-page-title,
  #head {
    margin-top: 50px;
  }
}
.cb-page-selected-layout8 .cb-page-title,
.cb-page-selected-layout8 #head {
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 1);
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #F4F4F4;
  font-family: 'brandon-grotesque', sans-serif;
  font-size: 20px;
  line-height: 1.3;
  color: var(--s-textcolor);
  overflow: hidden;
  hyphens: none;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  padding-top: 100px;
  box-sizing: border-box;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section--two .desk {
  width: 100%;
  max-width: 100%;
}
.section--backBlog .desk {
  --deskWidth: 910px;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.cb-pages {
  float: left;
  width: 100%;
}
.cb-page {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  position: fixed;
  top: 0;
  --deskWidth: 1840px;
  padding: 21px 0;
  box-sizing: border-box;
  z-index: 2000;
  background-color: #F4F4F4;
  transition: all 0.2s cubic-bezier(0.65, 0, 0.35, 1);
}
.section--header.navi--hidden {
  transform: translateY(-100%);
}
@media (max-width: 767px) {
  .section--header {
    padding: 30px 0;
  }
}
body.cb-toggle-target-active .section--header {
  background-color: transparent;
}
.container {
  float: left;
  width: 100%;
}
.container--head {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.service_login {
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .service_login:hover,
  .service_login:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .service_login:active {
    background-color: var(--s-btn-bg-hov);
  }
}
.mobile-navigation .service_login {
  --s-btn-border: #F4F4F4;
  --s-btn-bg: #232935;
  --s-btn-bg-hov: #F4F4F4;
  --s-btn-txt: #F4F4F4;
  --s-btn-txt-hov: #232935;
  font-size: 30px;
}
.navigation {
  float: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}
main {
  float: left;
  width: 100%;
  position: relative;
  flex: 1 0 auto;
  padding-bottom: 220px;
  box-sizing: border-box;
}
@media (min-width: 1280px) {
  main {
    padding-bottom: 440px;
  }
}
.section--greyblue {
  background-color: #232935;
  --s-textcolor: #F4F4F4;
  --s-sitecolor: #0AD705;
  --s-linkcolor: #F4F4F4;
  --s-linkcolor-hov: #0AD705;
  --s-btn-border: #F4F4F4;
  --s-btn-bg: #232935;
  --s-btn-bg-hov: #F4F4F4;
  --s-btn-txt: #F4F4F4;
  --s-btn-txt-hov: #232935;
}
.section--petrol {
  background-color: #024C4D;
  --s-textcolor: #F4F4F4;
  --s-sitecolor: #0AD705;
  --s-linkcolor: #F4F4F4;
  --s-linkcolor-hov: #0AD705;
  --s-btn-border: #F4F4F4;
  --s-btn-bg: #024C4D;
  --s-btn-bg-hov: #F4F4F4;
  --s-btn-txt: #F4F4F4;
  --s-btn-txt-hov: #024C4D;
}
.section--gradient {
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(45deg, #006668 0%, #0AD705 100%);
  --s-textcolor: #F4F4F4;
  --s-sitecolor: #0AD705;
  --s-linkcolor: #F4F4F4;
  --s-linkcolor-hov: #0AD705;
  --s-btn-border: #F4F4F4;
  --s-btn-bg: transparent;
  --s-btn-bg-hov: #F4F4F4;
  --s-btn-txt: #F4F4F4;
  --s-btn-txt-hov: #232935;
}
.section--blogTeaser {
  margin-top: calc(var(--spaceTotal) * 4);
}
@media (max-width: 767px) {
  .section--blogTeaser {
    margin-top: calc(var(--spaceTotal) * 2);
  }
}
.designelementWrapper {
  position: absolute;
  padding-topw: 200px;
  box-sizing: border-box;
  left: 5vw;
  width: 90vw;
  height: 100%;
  pointer-events: none;
}
.designelement {
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-position: 50% 0;
  background-repeat: repeat-y;
  background-image: url(/images/designelement.svg);
}
.designElementOff .designelement {
  display: none;
}
.container--mood {
  min-height: calc(100vh - 100px);
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  min-height: calc(100svh - 100px);
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  isolation: isolate;
  padding-top: calc(var(--spaceTotal) * 1);
  padding-bottom: 74px;
  box-sizing: border-box;
  --s-textcolor: #F4F4F4;
}
.container--mood .mood {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #232935;
}
.container--mood .content {
  width: 1320px;
  max-width: var(--deskMaxWidth);
}
.container--columns {
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 2);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .container--columns {
    margin-top: calc(var(--spaceTotal) * 1);
    margin-bottom: calc(var(--spaceTotal) * 1);
  }
}
.container--job {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.container--job .mood {
  position: relative;
  float: left;
  width: 100%;
}
@media (max-width: 1279px) {
  .container--job .mood {
    aspect-ratio: 1;
  }
  @supports not (aspect-ratio: 1 /  1) {
    .container--job .mood:before {
      float: left;
      padding-top: 100%;
      content: '';
    }
    .container--job .mood:after {
      display: block;
      content: '';
      clear: both;
    }
  }
}
.container--job .content {
  padding-left: max(var(--deskSpace), calc((100vw - 1320px) / 2));
  padding-right: var(--deskSpace);
  box-sizing: border-box;
}
.teaserProjectInfo {
  outline: 2px dashed #71caf3;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
#view .teaserProjectInfo {
  display: none;
}
.cbdModule--ctaButtonStart {
  margin-bottom: calc(var(--spaceTotal) * 2);
}
@media (min-width: 1280px) {
  .cbdModule--ctaButtonStart {
    position: absolute;
    top: 250px;
    right: var(--deskSpace);
    width: auto;
  }
}
.cbdModule--ctaButtonEnd {
  margin-top: calc(var(--spaceTotal) * 4);
  position: absolute;
  bottom: calc(var(--deskSpace) * -1);
  right: calc(var(--deskSpace) * 2);
  width: auto;
}
.ctaButton {
  float: right;
  width: 220px;
  color: #F4F4F4;
  aspect-ratio: 1;
  padding: 40px;
  box-sizing: border-box;
  background-color: #232935;
  border-radius: 90px;
  border-bottom-right-radius: 40px;
  font-size: 26px;
  line-height: 1.15384615;
  cursor: pointer;
}
@supports not (aspect-ratio: 1 /  1) {
  .ctaButton:before {
    float: left;
    padding-top: 100%;
    content: '';
  }
  .ctaButton:after {
    display: block;
    content: '';
    clear: both;
  }
}
.show-content .ctaButton {
  animation-name: animCtaButton;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
}
@media (min-width: 1280px) {
  .ctaButton {
    width: 350px;
    padding: 100px;
    font-size: 40px;
    line-height: 1.25;
    border-radius: 130px;
    border-bottom-right-radius: 60px;
  }
}
.ctaButton a {
  color: #F4F4F4;
}
.ctaButton--start {
  rotate: -7deg;
}
.ctaButton--end {
  rotate: -7deg;
}
@keyframes animCtaButton {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}
.backaction {
  float: left;
  width: 100%;
  margin-top: calc(var(--spaceTotal) * 1);
  margin-bottom: calc(var(--spaceTotal) * 2);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.backaction__link {
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .backaction__link:hover,
  .backaction__link:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .backaction__link:active {
    background-color: var(--s-btn-bg-hov);
  }
}
.section--footer {
  --s-textcolor: #F4F4F4;
  --s-linkcolor: #F4F4F4;
  --spacePart: 10px;
  --spaceUnit: 10px;
  --spaceTotal: 10px;
  flex-shrink: 0;
  --deskWidth: 1840px;
}
.section--footer .footer {
  float: left;
  width: 100%;
  background-color: #024C4D;
}
.footarea {
  float: left;
  width: 100%;
  margin-top: calc(var(--spaceTotal) * 1 + var(--spacePart));
  margin-bottom: calc(var(--spaceTotal) * 1);
}
.footpart {
  float: left;
  width: 100%;
  margin-top: calc(var(--spacePart) * 1);
  margin-bottom: calc(var(--spacePart) * 1);
  color: var(--s-textcolor);
  font-size: 22px;
  line-height: 1.36363636;
}
@media (max-width: 767px) {
  .footpart {
    font-size: 30px;
    line-height: 1.33333333;
  }
}
.vcard {
  color: var(--s-textcolor);
}
.tel,
.vcardemail {
  color: var(--s-textcolor);
  font-weight: 500;
}
.footnav .sub1 {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footnav .sub1 > .item {
  margin-bottom: 5px;
}
.footnav .sub1 > .item > .menu {
  font-weight: 500;
}
.area--filter .unit {
  margin-top: 0;
  margin-bottom: 0;
}
.area--filter .unit .part {
  margin-top: 0;
}
.area--filter .unit .part.link {
  margin-top: 0;
  margin-bottom: 5px;
}
.getintouch {
  float: right;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
}
.emailicon {
  float: left;
  width: 45px;
  height: 32px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-mail-white.svg);
  margin-right: 20px;
}
@media (hover: hover) and (pointer: fine) {
  .emailicon:hover,
  .emailicon:focus {
    background-image: url(/images/icon-mail-green.svg);
  }
}
#social .meta {
  float: left;
  width: 30px;
  height: 30px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-linkedin-white.svg);
  margin-right: 20px;
  transition: unset;
}
@media (hover: hover) and (pointer: fine) {
  #social .meta:hover,
  #social .meta:focus {
    background-image: url(/images/icon-linkedin-green.svg);
  }
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta.service_instagram {
  background-image: url(/images/icon-instagram-white.svg);
}
@media (hover: hover) and (pointer: fine) {
  #social .meta.service_instagram:hover,
  #social .meta.service_instagram:focus {
    background-image: url(/images/icon-instagram-green.svg);
  }
}
#legal {
  float: right;
  font-size: 20px;
  line-height: 1.2;
}
#cb-cookie-warning {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1990;
  width: 100%;
  background-color: #F4F4F4;
  display: none;
  flex-direction: column;
  align-items: center;
  font-family: 'brandon-grotesque', sans-serif;
  font-size: 20px;
  line-height: 1.3;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
}
.show-content #cb-cookie-warning {
  display: flex;
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none;
}
@media (max-width: 767px) {
  #cb-cookie-warning {
    text-align: center;
  }
}
.cb-cookie-warning--container {
  float: left;
  width: var(--deskWidth);
  max-width: var(--deskMaxWidth);
  display: flex;
  align-items: center;
  margin: 36px 0;
}
@media (max-width: 1279px) {
  .cb-cookie-warning--container {
    margin: 20px 0;
  }
}
@media (max-width: 767px) {
  .cb-cookie-warning--container {
    flex-direction: column;
    align-items: center;
  }
}
.cb-cookie-warning--text {
  float: left;
}
.cb-cookie-warning--text strong {
  display: inline-block;
  padding-bottom: 6px;
}
.cb-cookie-warning--actions {
  float: left;
  margin-left: 100px;
  display: flex;
  align-items: center;
}
@media (max-width: 1279px) {
  .cb-cookie-warning--actions {
    margin-left: 40px;
  }
}
@media (max-width: 767px) {
  .cb-cookie-warning--actions {
    margin-left: 0;
    margin-top: 20px;
  }
}
.cb-cookie-warning__button {
  float: left;
  font-family: 'brandon-grotesque', sans-serif;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  white-space: nowrap;
  margin-right: 40px;
}
@media (hover: hover) and (pointer: fine) {
  .cb-cookie-warning__button:hover,
  .cb-cookie-warning__button:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .cb-cookie-warning__button:active {
    background-color: var(--s-btn-bg-hov);
  }
}
.cb-cookie-warning__button:last-child {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}
@media (max-width: 1279px) {
  .cb-cookie-warning__button {
    min-width: 0;
    margin-right: 20px;
  }
}
a {
  color: var(--s-linkcolor);
  text-decoration: none;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
.ns-unitFilter__link a,
.norm a,
.loud a {
  text-decoration: underline;
  text-underline-position: under;
}
@media (hover: hover) and (pointer: fine) {
  .ns-unitFilter__link a:hover,
  .norm a:hover,
  .loud a:hover,
  .ns-unitFilter__link a:focus,
  .norm a:focus,
  .loud a:focus {
    text-decoration-color: #0AD705;
  }
}
.footpart:not(.footpart--half) a {
  position: relative;
  padding-bottom: 2px;
}
.footpart:not(.footpart--half) a:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 0;
  height: 3px;
  background-color: var(--s-linkcolor);
  transition: all 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .footpart:not(.footpart--half) a:hover:after,
  .footpart:not(.footpart--half) a:focus:after,
  .footpart:not(.footpart--half) a.path:after {
    width: 100%;
  }
}
.area--column .part.link:not(.socialIcon) .open,
.area--offer .part.link:not(.socialIcon) .open,
.area--intro .part.link:not(.socialIcon) .open,
.area--job .part.link:not(.socialIcon) .open,
.area--one .part.link:not(.socialIcon) .open {
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .area--column .part.link:not(.socialIcon) .open:hover,
  .area--offer .part.link:not(.socialIcon) .open:hover,
  .area--intro .part.link:not(.socialIcon) .open:hover,
  .area--job .part.link:not(.socialIcon) .open:hover,
  .area--one .part.link:not(.socialIcon) .open:hover,
  .area--column .part.link:not(.socialIcon) .open:focus,
  .area--offer .part.link:not(.socialIcon) .open:focus,
  .area--intro .part.link:not(.socialIcon) .open:focus,
  .area--job .part.link:not(.socialIcon) .open:focus,
  .area--one .part.link:not(.socialIcon) .open:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .area--column .part.link:not(.socialIcon) .open:active,
  .area--offer .part.link:not(.socialIcon) .open:active,
  .area--intro .part.link:not(.socialIcon) .open:active,
  .area--job .part.link:not(.socialIcon) .open:active,
  .area--one .part.link:not(.socialIcon) .open:active {
    background-color: var(--s-btn-bg-hov);
  }
}
.cbdModule--teaserSliderMoreButton {
  margin-top: calc(var(--spaceTotal) * 1);
  margin-bottom: calc(var(--spaceTotal) * 1);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cbdModule--teaserSliderMoreButton .open {
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .cbdModule--teaserSliderMoreButton .open:hover,
  .cbdModule--teaserSliderMoreButton .open:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .cbdModule--teaserSliderMoreButton .open:active {
    background-color: var(--s-btn-bg-hov);
  }
}
.socialIcon {
  width: auto;
}
.socialIcon .open {
  float: left;
  width: 25px;
  height: 18px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: var(--s-getintouch-icon);
  margin-right: 20px;
  transition: unset;
}
@media (hover: hover) and (pointer: fine) {
  .socialIcon .open:hover,
  .socialIcon .open:focus {
    background-image: var(--s-getintouch-icon-hov);
  }
}
.socialIcon--linkedin {
  --s-getintouch-icon: url(/images/icon-linkedin-greyblue.svg);
  --s-getintouch-icon-hov: url(/images/icon-linkedin-green.svg);
}
.socialIcon--linkedin .open {
  width: 18px;
  height: 18px;
}
.socialIcon--instagram {
  --s-getintouch-icon: url(/images/icon-instagram-greyblue.svg);
  --s-getintouch-icon-hov: url(/images/icon-instagram-green.svg);
}
.socialIcon--instagram .open {
  width: 18px;
  height: 18px;
}
h1 {
  font-size: clamp(6.25rem, -1.25rem + 9.38vw, 10rem);
  line-height: 0.875;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  font-weight: 900;
  color: var(--s-textcolor);
}
h1 strong {
  font-weight: 900;
  padding-right: 10px;
  box-sizing: border-box;
  background-image: linear-gradient(to bottom, #006668, #0ad705);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
@media (max-width: 767px) {
  h1 {
    font-size: clamp(3rem, 2.107rem + 4.46vw, 4.25rem);
  }
}
.cb-page-selected-layout8 h1 {
  font-size: 80px;
  line-height: 1.125;
  letter-spacing: -0.05em;
  color: var(--s-textcolor);
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
}
@media (max-width: 767px) {
  .cb-page-selected-layout8 h1 {
    font-size: 50px;
    line-height: 1;
  }
}
h2 {
  font-size: 80px;
  line-height: 1.125;
  letter-spacing: -0.05em;
  color: var(--s-textcolor);
  font-weight: normal;
  text-decoration: none;
}
@media (max-width: 767px) {
  h2 {
    font-size: 50px;
    line-height: 1;
  }
}
h3 {
  font-size: 40px;
  line-height: 1;
  letter-spacing: -0.03em;
  color: var(--s-textcolor);
  font-weight: 500;
}
@media (max-width: 767px) {
  h3 {
    font-size: 30px;
    line-height: 1.33333333;
  }
}
h4 {
  font-size: 30px;
  line-height: 1.33333333;
  letter-spacing: -0.05em;
  color: var(--s-textcolor);
  font-weight: 500;
}
@media (max-width: 767px) {
  h4 {
    font-size: 20px;
    line-height: 1.5;
  }
}
p.skew {
  font-size: 30px;
  line-height: 1.13333333;
  font-weight: 300;
  color: var(--s-textcolor);
}
p.skew strong {
  font-weight: 500;
}
@media (max-width: 767px) {
  p.skew {
    font-size: 24px;
    line-height: 1;
  }
}
p.norm {
  color: var(--s-textcolor);
  font-size: 24px;
  line-height: 1.25;
}
@media (max-width: 1279px) {
  p.norm {
    font-size: 22px;
    line-height: 1.45454545;
  }
}
p.norm .open {
  text-decoration: underline;
  text-underline-offset: 2px;
}
p.loud {
  font-size: 28px;
  line-height: 1.28571429;
  letter-spacing: -0.05em;
  font-weight: 300;
  color: var(--s-textcolor);
}
p.loud strong {
  font-weight: 500;
}
@media (max-width: 1279px) {
  p.loud {
    font-size: 24px;
    line-height: 1.33333333;
  }
}
@media (max-width: 767px) {
  p.loud {
    font-size: 22px;
    line-height: 1.36363636;
  }
}
.area--intro {
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 2);
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .area--intro {
    margin-top: calc(var(--spaceTotal) * 1);
    margin-bottom: calc(var(--spaceTotal) * 1);
  }
}
.area--one {
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 2);
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .area--one {
    margin-top: calc(var(--spaceTotal) * 1);
    margin-bottom: calc(var(--spaceTotal) * 1);
  }
}
.cb-page-layout5 .area--one.area1 {
  margin-bottom: 0;
}
.area--one.areaFilterContent {
  margin-top: calc(var(--spaceTotal) * 1);
}
.area--column {
  margin-top: calc(var(--spaceTotal) * 1);
  margin-bottom: calc(var(--spaceTotal) * 1);
}
.area--one .unitOne--1-4 .cb-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.area--one .unitOne--1-4 .part.pict {
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  #view .area--one .unitOne--1-4 .part.pict:hover,
  #view .area--one .unitOne--1-4 .part.pict:focus {
    transform: scale(1.1);
  }
}
.area--one .unitTwo .unit__background {
  position: relative;
  isolation: isolate;
  aspect-ratio: 1.78378378;
}
@supports not (aspect-ratio: 1320 /  740) {
  .area--one .unitTwo .unit__background:before {
    float: left;
    padding-top: 56.06060606%;
    content: '';
  }
  .area--one .unitTwo .unit__background:after {
    display: block;
    content: '';
    clear: both;
  }
}
.area--one .unitTwo .unit__background * {
  height: 100%;
}
.area--one .unitTwo .unit__background img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--one .unitTwo .unit__background:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
  z-index: 2;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
#view .area--one .unitTwo .unit__background:after {
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  opacity: 0;
}
@media (max-width: 1279px) {
  .area--one .unitTwo .unit__background {
    width: 100vw;
    margin-left: calc(var(--deskSpace) * -1);
  }
}
.area--one .unitTwo .part:nth-child(1) {
  --s-textcolor: #F4F4F4;
  position: absolute;
  top: 180px;
  z-index: 12;
  text-align: center;
  text-transform: uppercase;
}
@media (max-width: 1279px) {
  .area--one .unitTwo .part:nth-child(1) {
    top: 50px;
  }
}
@media (max-width: 767px) {
  .area--one .unitTwo .part:nth-child(1) {
    top: 30px;
  }
}
.area--one .unitTwo .part:nth-child(1) h2 {
  font-weight: 900;
}
#view .area--one .unitTwo .part:nth-child(1) {
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
@media (max-width: 1279px) {
  #view .area--one .unitTwo .part:nth-child(1) {
    display: none;
  }
}
.area--one .unitTwo .part:nth-child(2) {
  position: absolute;
  top: 380px;
  z-index: 12;
  text-align: center;
  --s-btn-border: #F4F4F4;
  --s-btn-bg: transparent;
  --s-btn-bg-hov: #F4F4F4;
  --s-btn-txt: #F4F4F4;
  --s-btn-txt-hov: #232935;
}
@media (max-width: 1279px) {
  .area--one .unitTwo .part:nth-child(2) {
    top: 260px;
  }
}
@media (max-width: 767px) {
  .area--one .unitTwo .part:nth-child(2) {
    top: 150px;
  }
}
#view .area--one .unitTwo .part:nth-child(2) {
  opacity: 0;
}
@media (max-width: 1279px) {
  #view .area--one .unitTwo .part:nth-child(2) {
    display: none;
  }
}
@media (hover: hover) and (pointer: fine) {
  #view .area--one .unitTwo.unit--isAnchor:hover .unit__background:after,
  #view .area--one .unitTwo.unit--isAnchor:focus .unit__background:after {
    opacity: 0.3;
  }
  #view .area--one .unitTwo.unit--isAnchor:hover .part:nth-child(1),
  #view .area--one .unitTwo.unit--isAnchor:focus .part:nth-child(1) {
    opacity: 1;
  }
  #view .area--one .unitTwo.unit--isAnchor:hover .part:nth-child(2),
  #view .area--one .unitTwo.unit--isAnchor:focus .part:nth-child(2) {
    opacity: 1;
  }
}
.area--one .unitThree .unit__backgroundImage {
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1;
}
@supports not (aspect-ratio: 1 /  1) {
  .area--one .unitThree .unit__backgroundImage:before {
    float: left;
    padding-top: 100%;
    content: '';
  }
  .area--one .unitThree .unit__backgroundImage:after {
    display: block;
    content: '';
    clear: both;
  }
}
.area--column .unitOne:not(.unitOne--1-1-indentLeft) {
  margin-top: 10px;
  margin-bottom: 10px;
}
.area--column .unitOne:not(.unitOne--1-1-indentLeft) .part.pict {
  --spacePart: 0;
}
.area--column .unitOne--bg:not(.unitOne--bgOff) {
  --s-UnitBackgroundColor: #E9EAEA;
}
@media (max-width: 767px) {
  .area--column .unitOne--bg:not(.unitOne--bgOff) {
    margin-left: calc(var(--deskSpace) * -1);
    margin-right: calc(var(--deskSpace) * -1);
    width: 100vw;
    padding: 0 var(--deskSpace);
    box-sizing: border-box;
    background-color: var(--s-UnitBackgroundColor);
  }
}
.area--column .unitOne--bg:not(.unitOne--bgOff) .unit__body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-between;
}
.area--column .unitOne--bg:not(.unitOne--bgOff) .unit__content {
  background-color: var(--s-UnitBackgroundColor);
  padding: var(--spaceUnit) var(--spaceTotal);
  box-sizing: border-box;
}
.area--column .unitOne--bg:not(.unitOne--bgOff).unitOne--bgBlue {
  --s-UnitBackgroundColor: #232935;
  --s-textcolor: #F4F4F4;
  --s-sitecolor: #0AD705;
  --s-linkcolor: #F4F4F4;
  --s-linkcolor-hov: #0AD705;
  --s-getintouch-icon: url(/images/icon-mail-white.svg);
}
.area--column .unitOne--bg:not(.unitOne--bgOff).unitOne--bgPetrol {
  --s-UnitBackgroundColor: #024C4D;
  --s-textcolor: #F4F4F4;
  --s-sitecolor: #0AD705;
  --s-linkcolor: #F4F4F4;
  --s-linkcolor-hov: #0AD705;
}
.area--column .unitOne--bg:not(.unitOne--bgOff).unitOne--bgGreen {
  --s-UnitBackgroundColor: #0AD705;
  --s-sitecolor: #024C4D;
  --s-linkcolor-hov: #024C4D;
}
.area--column .unitOne--bg:not(.unitOne--bgOff).unitOne--bgGradient {
  --s-UnitBackgroundColor: #232935;
  --s-textcolor: #F4F4F4;
  --s-sitecolor: #0AD705;
  --s-linkcolor: #F4F4F4;
  --s-linkcolor-hov: #0AD705;
  --s-getintouch-icon: url(/images/icon-mail-white.svg);
}
.area--column .unitOne--bg:not(.unitOne--bgOff).unitOne--bgGradient .unit__content {
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(45deg, #006668 0%, #0AD705 100%);
}
.section--sliderBlog {
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 2);
}
@media (max-width: 767px) {
  .section--sliderBlog {
    margin-top: calc(var(--spaceTotal) * 1);
    margin-bottom: calc(var(--spaceTotal) * 1);
  }
}
.cbdModule--blogSliderTitle {
  margin-top: calc(var(--spaceTotal) * 2);
}
@media (max-width: 767px) {
  .cbdModule--blogSliderTitle {
    margin-top: calc(var(--spaceTotal) * 1);
  }
}
.cb-page-selected-layout6 .cbdModule--blogSliderTitle {
  margin-top: 0;
}
.cbdModule--testimonialSliderTitle {
  margin-top: calc(var(--spaceTotal) * 2);
}
@media (max-width: 767px) {
  .cbdModule--testimonialSliderTitle {
    margin-top: calc(var(--spaceTotal) * 1);
  }
}
.cb-page-selected-layout6 .cbdModule--testimonialSliderTitle {
  margin-top: 0;
}
.part--standaloneFile {
  padding-right: 20%;
  box-sizing: border-box;
}
.part--standaloneFile img {
  width: 100%;
  max-height: unset!important;
  max-width: unset!important;
}
.part--standaloneText {
  padding-right: 30%;
  box-sizing: border-box;
}
.area--column .unit--fold {
  margin-top: 10px;
  margin-bottom: 0;
}
.area--column .unit--fold + .unit--fold {
  margin-top: -2px;
}
.area--column .unit--fold div.less .part {
  margin-top: 0;
  margin-bottom: 0;
}
.area--column .unit--fold div.more {
  display: block !important;
  height: 0;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.65, 0, 0.35, 1);
  padding-left: calc(var(--spaceTotal) * 1);
  padding-right: calc(var(--spaceTotal) * 1);
  box-sizing: border-box;
}
@media (min-width: 1280px) {
  .area--column .unit--fold div.more {
    padding-left: 60px;
    padding-right: 60px;
  }
}
.area--column .unit--fold div.more .part {
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.2s;
}
.area--column .unit--fold div.more .part:first-child {
  margin-top: 10px;
}
.area--column .unit--fold div.more .part:last-child {
  margin-bottom: 0;
}
.area--column .unit--fold .fold-toggle {
  border-top: 2px solid #232935;
  border-bottom: 2px solid #232935;
  hyphens: auto;
  float: left;
  padding: calc(var(--spaceTotal) * 1);
  box-sizing: border-box;
  position: relative;
  width: 100%;
}
@media (min-width: 1280px) {
  .area--column .unit--fold .fold-toggle {
    padding-top: 60px;
    padding-bottom: 60px;
    padding-right: 60px;
    padding-left: 220px;
  }
  .area--column .unit--fold .fold-toggle strong {
    font-size: 80px;
    position: absolute;
    left: 56px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.area--column .unit--fold .fold-toggle:after {
  content: '';
  position: absolute;
  width: 14px;
  height: 7px;
  right: 20px;
  top: calc(50%);
  transform: translateY(-50%);
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-arrow-greyblue.svg);
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (min-width: 1280px) {
  .area--column .unit--fold .fold-toggle:after {
    right: 45px;
  }
}
.area--column .unit--fold .fold-toggle--open {
  border-bottom: 2px solid transparent;
}
.area--column .unit--fold .fold-toggle--open:after {
  transform: translateY(-50%) scaleY(-1);
}
.area--column .unit--fold.unit--foldOpen div.more {
  height: calc(var(--js-elementHeight) + (var(--spaceTotal) * 1));
}
.area--column .unit--fold.unit--foldOpen div.more .part {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s 0.2s;
}
.area--column .unit--fold.unit--foldOpen div.more .part:last-child .part__content {
  float: left;
}
.area--column .unit--fold.unit--foldOpen:last-child {
  border-bottom: 2px solid #232935;
}
.area--column .unit--fold .unit__foot {
  display: none;
}
.area--offer .unit--fold {
  margin-top: -2px;
  margin-bottom: 0;
}
.area--offer .unit--fold + .unit--fold {
  margin-top: -2px;
}
.area--offer .unit--fold .unit__background {
  width: 60px;
  position: absolute;
  left: calc(var(--spaceTotal) * 1);
  top: calc(var(--spaceTotal) + 5px);
}
@media (min-width: 1279px) {
  .area--offer .unit--fold .unit__background {
    width: 120px;
    left: 56px;
  }
}
.area--offer .unit--fold:not(.unit--foldOpen) .unit__backgroundImage {
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  filter: invert(100%) brightness(10);
}
.area--offer .unit--fold div.less .part {
  margin-top: 0;
  margin-bottom: 0;
}
.area--offer .unit--fold div.more {
  display: block !important;
  height: 0;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.65, 0, 0.35, 1);
  padding-left: calc(var(--spaceTotal) * 1);
  padding-right: calc(var(--spaceTotal) * 1);
  box-sizing: border-box;
}
#edit .area--offer .unit--fold div.more {
  height: auto;
}
@media (min-width: 1280px) {
  .area--offer .unit--fold div.more {
    padding-left: 60px;
    padding-right: 60px;
  }
}
.area--offer .unit--fold div.more .part {
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.2s;
}
.area--offer .unit--fold div.more .part:first-child {
  margin-top: 10px;
}
.area--offer .unit--fold .fold-toggle {
  border-top: 2px solid #F4F4F4;
  border-bottom: 2px solid #F4F4F4;
  hyphens: auto;
  float: left;
  padding: calc(var(--spaceTotal) * 1);
  padding-left: calc(var(--spaceTotal) + 80px);
  box-sizing: border-box;
  position: relative;
  width: 100%;
}
@media (min-width: 1280px) {
  .area--offer .unit--fold .fold-toggle {
    padding-top: 60px;
    padding-bottom: 60px;
    padding-right: 60px;
    padding-left: 220px;
  }
}
.area--offer .unit--fold .fold-toggle:after {
  content: '';
  position: absolute;
  width: 14px;
  height: 7px;
  right: 20px;
  top: calc(50%);
  transform: translateY(-50%);
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-arrow-white.svg);
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (min-width: 1280px) {
  .area--offer .unit--fold .fold-toggle:after {
    right: 45px;
  }
}
.area--offer .unit--fold .fold-toggle--open {
  border-bottom: 2px solid transparent;
}
.area--offer .unit--fold .fold-toggle--open:after {
  background-image: url(/images/icon-arrow-greyblue.svg);
  transform: translateY(-50%) scaleY(-1);
}
.area--offer .unit--fold.unit--foldOpen {
  background-color: #F4F4F4;
  --s-textcolor: #232935;
  --s-sitecolor: #0AD705;
  --s-linkcolor: #232935;
  --s-linkcolor-hov: #0AD705;
  --s-btn-border: #232935;
  --s-btn-bg: #F4F4F4;
  --s-btn-bg-hov: #232935;
  --s-btn-txt: #232935;
  --s-btn-txt-hov: #F4F4F4;
}
.area--offer .unit--fold.unit--foldOpen div.more {
  height: calc(var(--js-elementHeight) + 30px);
}
.area--offer .unit--fold.unit--foldOpen div.more .part {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s 0.2s;
}
.area--offer .unit--fold.unit--foldOpen div.more .part:last-child .part__content {
  float: left;
  padding-bottom: 20px;
  border-bottom: 2px solid #F4F4F4;
}
.area--offer .unit--fold .unit__foot {
  display: none;
}
.area--one .unitOne--1-1-indentBoth h2 {
  font-size: 120px;
  font-weight: 500;
}
@media (max-width: 1279px) {
  .area--one .unitOne--1-1-indentBoth h2 {
    font-size: 80px;
  }
}
@media (max-width: 767px) {
  .area--one .unitOne--1-1-indentBoth h2 {
    font-size: 50px;
  }
}
.area--one .unitOne--1-1-indentBoth h2 strong {
  font-weight: 500;
  background-image: linear-gradient(to right, #006668, #0ad705);
  background-position: 0% calc(100% - 20px);
  background-repeat: no-repeat;
  background-size: 0 12px;
  transition: background-size 0.3s;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (max-width: 1279px) {
  .area--one .unitOne--1-1-indentBoth h2 strong {
    background-position: 0% calc(100% - 10px);
    background-size: 0 4px;
  }
}
.area--one .unitOne--1-1-indentBoth.is-intersecting h2 strong {
  background-size: calc(100% + 5px) 12px;
}
@media (max-width: 1279px) {
  .area--one .unitOne--1-1-indentBoth.is-intersecting h2 strong {
    background-size: calc(100% + 5px) 4px;
  }
}
.area--two .unit .part.pict {
  overflow: hidden;
  height: 40vh;
}
.area--two .unit .part.pict * {
  height: 100%;
}
.area--two .unit .part.pict img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 767px) {
  .area--two .unit .part.pict .cb-image-container {
    height: 66vw;
  }
}
.area--two .unit .part.pict .cb-image-container img {
  object-fit: cover;
  object-position: center;
  height: 125% !important;
  max-height: 125% !important;
}
.partAlign--left {
  text-align: left;
}
.partAlign--center {
  text-align: center;
}
.partAlign--right {
  text-align: right;
}
@media (max-width: 1279px) {
  body.cb-toggle-target-active {
    overflow: hidden;
  }
  .navigation {
    display: none;
  }
}
.togglenavigation {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 2001;
  width: 35px;
  height: 20px;
  transform: translateY(-50%);
  cursor: pointer;
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 3px;
  background-color: var(--s-textcolor);
  border-radius: 3px;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline {
  --s-textcolor: #F4F4F4;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100%;
  height: 100%;
  background-color: #232935;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.65, 0, 0.35, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0s 0.6s cubic-bezier(0.65, 0, 0.35, 1);
  box-sizing: border-box;
  padding-left: calc(var(--deskSpace) * 2);
  padding-right: var(--deskSpace);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transition: all 1s cubic-bezier(0.65, 0, 0.35, 1);
  transform: translateY(0);
  opacity: 1;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: var(--s-textcolor);
  text-decoration: unset;
}
.mobile-navigation div.navi > .item > .menu.path {
  color: var(--s-sitecolor);
}
.mobile-navigation div.sub1 {
  margin: 140px 0 30px;
}
.mobile-navigation div.sub1 > .item {
  margin-bottom: 15px;
}
.mobile-navigation div.sub1 > .item > .menu {
  font-size: 40px;
  line-height: 1;
  color: #F4F4F4;
  font-weight: bold;
  padding-right: 35px;
}
.mobile-navigation div.sub2 {
  overflow: hidden;
  max-height: 0;
}
.mobile-navigation div.sub2 > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000px;
  transition: max-height 2s;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}
.mobile-navigation .cb-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 35px;
  height: 24px;
  background-size: 14px 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-down-black.svg);
  transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
}
.ns-unitFilter__unit {
  display: none;
}
.ns-unitFilter__unit.ns-unitFilter__unit--activated {
  display: block;
  opacity: 0;
  transform: translateY(-20px) scale(0.98);
  transition: transform 0.4s, opacity 0.4s !important;
}
.ns-unitFilter__unit.ns-unitFilter__unit--active {
  opacity: 1;
  transform: translateY(0) scale(1);
}
.ns-unitFilter {
  float: left;
  width: 100%;
  position: relative;
  z-index: 20;
  margin-top: calc(var(--spacePart) * 1);
}
.ns-unitFilter__wrapper {
  float: left;
  width: 100%;
  position: relative;
}
.ns-unitFilter__toggle {
  display: none;
}
.ns-unitFilter__content {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}
.ns-unitFilter__list {
  float: left;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
}
.ns-unitFilter__actions {
  float: left;
  display: none;
  flex-wrap: wrap;
}
.ns-unitFilter__link {
  margin-bottom: 10px;
  color: #232935;
  cursor: pointer;
  transition: all cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  .ns-unitFilter__link:hover,
  .ns-unitFilter__link:focus {
    color: var(--s-sitecolor);
  }
}
@media (min-width: 1280px) {
  .ns-unitFilter__link--confirm {
    display: none;
  }
}
.ns-unitFilter__group {
  float: left;
  width: 100%;
  border-top: 1px solid var(--s-textcolor);
  border-bottom: 1px solid var(--s-textcolor);
  margin-top: -1px;
}
.ns-unitFilter__group:first-child {
  margin-top: 0;
}
.ns-unitFilter__groupToggle {
  float: left;
  width: 100%;
  font-size: 18px;
  line-height: 1.66666667;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  cursor: pointer;
  padding: 25px 20px 25px 0;
  box-sizing: border-box;
  position: relative;
  color: var(--s-textcolor);
}
.ns-unitFilter__groupToggle:hover,
.ns-unitFilter__groupToggle:focus {
  color: var(--s-textcolor);
}
.ns-unitFilter__groupToggle:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-style: solid;
  border-color: transparent;
  border-top-color: #000;
  border-width: 7px 5px 0 5px;
}
.ns-unitFilter__groupToggle.is-active:after {
  transform: translateY(-50%) rotate(-180deg);
}
.ns-unitFilter__groupContent {
  float: left;
  width: 100%;
  overflow: hidden;
  max-height: 0;
}
.ns-unitFilter__group.is-open .ns-unitFilter__groupContent {
  max-height: initial;
}
.ns-unitFilter__item {
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .ns-unitFilter__item:hover,
  .ns-unitFilter__item:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .ns-unitFilter__item:active {
    background-color: var(--s-btn-bg-hov);
  }
}
.ns-unitFilter__item.is-active {
  color: var(--s-btn-txt-hov);
  background-color: var(--s-btn-bg-hov);
}
@media (max-width: 1279px) {
  .ns-unitFilter__toggle {
    display: block;
    border: 3px solid var(--s-btn-border);
    background-color: var(--s-btn-bg);
    line-height: 1.3;
    color: var(--s-btn-txt);
    padding: 5px 20px;
    -webkit-appearance: none;
    border-radius: 0;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-weight: 500;
    font-size: 24px;
    line-height: 1;
    padding-right: 49px;
    font-family: 'brandon-grotesque', sans-serif;
    cursor: pointer;
    background-size: 16px 8px;
    background-position: calc(100% - 10px) 50%;
    background-repeat: no-repeat;
    background-image: url(/images/icon-arrow-greyblue.svg);
  }
  .ns-unitFilter__content {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 2;
    background-color: #F4F4F4;
    max-height: 0;
    overflow-y: auto;
    box-sizing: border-box;
    display: unset;
    margin-top: 20px;
    width: 100vw;
    padding: 0 calc(var(--deskSpace) * 1);
    margin-left: calc(var(--deskSpace) * -1);
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .ns-unitFilter__content::-webkit-scrollbar {
    display: none;
  }
  .is-open .ns-unitFilter__content {
    max-height: initial;
  }
  .ns-unitFilter__list {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: calc(var(--spacePart) * 1);
  }
  .ns-unitFilter__actions {
    column-gap: 30px;
    margin-top: calc(var(--spaceTotal) * 1);
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    display: none;
    flex-direction: row;
    justify-content: space-between;
  }
  .ns-unitFilter__link {
    margin-right: 0;
    margin-bottom: 0;
  }
  .ns-unitFilter__group:first-child {
    border-top: none;
  }
  .ns-unitFilter__group:last-child {
    border-bottom: none;
  }
  .ns-unitFilter__groupToggle {
    padding-left: 40px;
    padding-right: 60px;
  }
  .ns-unitFilter__groupToggle:after {
    right: 40px;
  }
  .ns-unitFilter__groupContent {
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
  }
  .ns-unitFilter__item {
    margin-right: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 1279px) and (hover: hover) and (pointer: fine) {
  .ns-unitFilter__toggle:hover,
  .ns-unitFilter__toggle:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .ns-unitFilter__toggle:active {
    background-color: var(--s-btn-bg-hov);
  }
}
@media (max-width: 1279px) and (hover: hover) and (pointer: fine) {
  .ns-unitFilter__toggle:hover,
  .ns-unitFilter__toggle:focus {
    background-image: url(/images/icon-arrow-white.svg);
  }
}
.ns-slider {
  float: left;
  width: 100%;
}
.ns-sliderWrapper {
  float: left;
  width: 100%;
}
.ns-slider__part--image .open,
.ns-slider__part--image .is-pseudoAnchor {
  float: left;
  width: 100%;
}
.ns-slider .ns-sliderIndex {
  float: left;
  width: 100%;
  margin-top: 30px;
}
.ns-slider .ns-sliderIndex__content {
  float: left;
  width: calc(100% + 20px);
  margin: -10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.ns-slider .ns-sliderIndex__item {
  float: left;
  width: 16px;
  height: 16px;
  border-radius: 10000px;
  background-color: #232935;
}
.ns-slider .ns-sliderIndex__item.is-active {
  background-color: var(--s-sitecolor);
}
.ns-slider .ns-sliderControl {
  position: absolute;
  float: left;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  height: 23px;
  width: 14px;
  border-radius: 100%;
  border: 4px solid transparent;
  cursor: pointer;
  background-size: 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (min-width: 1279px) {
  .ns-slider .ns-sliderControl {
    height: 56px;
    width: 56px;
  }
}
@media (min-width: 1279px) {
  .ns-slider .ns-sliderControl:hover,
  .ns-slider .ns-sliderControl:focus {
    border: 4px solid var(--s-textcolor);
  }
}
#view .is-noSlider .ns-slider .ns-sliderControl {
  display: none;
}
#view .ns-slider .is-empty {
  display: none;
}
.ns-slider .ns-sliderControl--prev {
  left: 0;
  background-image: url(/images/icon-arrow-left-greyblue.svg);
}
.section--petrol .ns-slider .ns-sliderControl--prev,
.section--gradient .ns-slider .ns-sliderControl--prev,
.section--greyblue .ns-slider .ns-sliderControl--prev {
  background-image: url(/images/icon-arrow-left-white.svg);
}
.ns-slider .ns-sliderControl--next {
  right: 0;
  background-image: url(/images/icon-arrow-right-greyblue.svg);
}
.section--petrol .ns-slider .ns-sliderControl--next,
.section--gradient .ns-slider .ns-sliderControl--next,
.section--greyblue .ns-slider .ns-sliderControl--next {
  background-image: url(/images/icon-arrow-right-white.svg);
}
.ns-slider--TeaserSlider .ns-slider__section {
  float: left;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.ns-slider--TeaserSlider .ns-slider__section:first-child {
  margin-top: 0;
}
.ns-slider--TeaserSlider .ns-slider__cover {
  float: left;
  width: 100%;
  position: relative;
}
.ns-slider--TeaserSlider .ns-slider__stage {
  float: left;
  width: 100%;
  overflow: hidden;
}
.ns-slider--TeaserSlider .ns-slider__area {
  float: left;
  width: calc(100% + 20px);
  margin-left: -10px;
  display: flex;
}
.ns-slider--TeaserSlider .ns-slide {
  float: left;
  width: calc((100%/3) - 20px);
  margin-left: 10px;
  margin-right: 10px;
  flex-shrink: 0;
  transition: margin 0.6s;
  padding-top: 30px;
  box-sizing: border-box;
}
@media (max-width: 1279px) {
  .ns-slider--TeaserSlider .ns-slide {
    width: calc((100%/2) - 20px);
  }
}
@media (max-width: 767px) {
  .ns-slider--TeaserSlider .ns-slide {
    width: calc((100%) - 20px);
  }
}
.ns-slider--TeaserSlider .ns-slide__content {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.ns-slider--TeaserSlider .ns-slide__section {
  float: left;
  position: relative;
  width: 100%;
}
.ns-slider--TeaserSlider .ns-slide__section--text {
  margin-top: calc(var(--spacePart) * 1);
}
#view .ns-slider--TeaserSlider .ns-slide__section--text {
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-slider--TeaserSlider .ns-slide__section--text {
    transform: translateY(-100%);
    opacity: 0;
  }
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-slider--TeaserSlider .ns-slide.is-anchor:hover .ns-slide__section--text,
  #view .ns-slider--TeaserSlider .ns-slide.is-anchor:focus .ns-slide__section--text {
    transform: translateY(0);
    opacity: 1;
  }
}
.ns-slider--TeaserSlider .ns-slider__part {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.ns-slider__part--text {
  color: var(--s-textcolor);
}
.ns-slider--TeaserSlider .ns-slider__part--image {
  aspect-ratio: 1;
  margin: 0;
  position: relative;
  z-index: 2;
}
@supports not (aspect-ratio: 1 /  1) {
  .ns-slider--TeaserSlider .ns-slider__part--image:before {
    float: left;
    padding-top: 100%;
    content: '';
  }
  .ns-slider--TeaserSlider .ns-slider__part--image:after {
    display: block;
    content: '';
    clear: both;
  }
}
#view .ns-slider--TeaserSlider .ns-slider__part--image:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 2;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-slider--TeaserSlider .ns-slide.is-anchor:hover .ns-slider__part--image:after,
  #view .ns-slider--TeaserSlider .ns-slide.is-anchor:focus .ns-slider__part--image:after {
    opacity: 0.3;
  }
}
.ns-slider--TeaserSlider .ns-slider__part--link {
  position: absolute;
  width: 100%;
  left: 50%;
  flex-direction: column;
  display: flex;
  align-items: center;
  top: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
  --s-btn-border: #F4F4F4;
  --s-btn-bg: transparent;
  --s-btn-bg-hov: #F4F4F4;
  --s-btn-txt: #F4F4F4;
  --s-btn-txt-hov: #232935;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-slider--TeaserSlider .ns-slider__part--link {
    opacity: 0;
    pointer-events: none;
  }
}
.ns-slider--TeaserSlider .ns-slider__part--link .open {
  float: left;
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .ns-slider--TeaserSlider .ns-slider__part--link .open:hover,
  .ns-slider--TeaserSlider .ns-slider__part--link .open:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .ns-slider--TeaserSlider .ns-slider__part--link .open:active {
    background-color: var(--s-btn-bg-hov);
  }
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-slider--TeaserSlider .ns-slide.is-anchor:hover .ns-slider__part--link,
  #view .ns-slider--TeaserSlider .ns-slide.is-anchor:focus .ns-slider__part--link {
    opacity: 1;
  }
}
.ns-slider--TeaserSlider .ns-slider__part--sliderIcon {
  max-width: 200px;
  margin-top: 0;
}
.ns-slider--TeaserSlider .ns-slider__part--sliderTitle,
.ns-slider--TeaserSlider .ns-slider__part--sliderLead {
  text-align: center;
  max-width: 800px;
}
.ns-slider--TeaserSlider .ns-sliderControl--prev {
  left: -25px;
}
@media (min-width: 1279px) {
  .ns-slider--TeaserSlider .ns-sliderControl--prev {
    left: -50px;
  }
}
.ns-slider--TeaserSlider .ns-sliderControl--next {
  right: -25px;
}
@media (min-width: 1279px) {
  .ns-slider--TeaserSlider .ns-sliderControl--next {
    right: -50px;
  }
}
.ns-teaserSlider {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: calc(var(--spaceTotal) * 3);
}
@media (max-width: 767px) {
  .ns-teaserSlider {
    margin-bottom: calc(var(--spaceTotal) * 1);
  }
}
.ns-slider--blog .ns-slider__section {
  float: left;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.ns-slider--blog .ns-slider__section:first-child {
  margin-top: 0;
}
.ns-slider--blog .ns-slider__cover {
  float: left;
  width: 100%;
  position: relative;
}
.ns-slider--blog .ns-slider__stage {
  float: left;
  width: 100%;
  overflow: hidden;
}
.ns-slider--blog .ns-slider__area {
  float: left;
  width: calc(100% + 20px);
  margin-left: -10px;
  display: flex;
}
.ns-slider--blog .ns-slide {
  float: left;
  width: calc((100%/3) - 20px);
  margin-left: 10px;
  margin-right: 10px;
  flex-shrink: 0;
  transition: margin 0.6s;
  padding-top: 30px;
  box-sizing: border-box;
}
@media (max-width: 1279px) {
  .ns-slider--blog .ns-slide {
    width: calc((100%/2) - 20px);
  }
}
@media (max-width: 767px) {
  .ns-slider--blog .ns-slide {
    width: calc((100%) - 20px);
  }
}
.ns-slider--blog .ns-slide__content {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.ns-slider--blog .ns-slide__section {
  float: left;
  position: relative;
  width: 100%;
}
.ns-slider--blog .ns-slide__section--text {
  margin-top: calc(var(--spacePart) * 1);
}
#view .ns-slider--blog .ns-slide__section--text {
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-slider--blog .ns-slide__section--text {
    transform: translateY(-100%);
    opacity: 0;
  }
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-slider--blog .ns-slide.is-anchor:hover .ns-slide__section--text,
  #view .ns-slider--blog .ns-slide.is-anchor:focus .ns-slide__section--text {
    transform: translateY(0);
    opacity: 1;
  }
}
.ns-slider--blog .ns-slider__part {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.ns-slider__part--text {
  color: var(--s-textcolor);
}
.ns-slider--blog .ns-slider__part--pict {
  aspect-ratio: 1;
  margin: 0;
  position: relative;
  z-index: 2;
}
@supports not (aspect-ratio: 1 /  1) {
  .ns-slider--blog .ns-slider__part--pict:before {
    float: left;
    padding-top: 100%;
    content: '';
  }
  .ns-slider--blog .ns-slider__part--pict:after {
    display: block;
    content: '';
    clear: both;
  }
}
.ns-slider--blog .ns-slider__part--pict * {
  height: 100%;
}
.ns-slider--blog .ns-slider__part--pict img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#view .ns-slider--blog .ns-slider__part--pict:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 2;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-slider--blog .ns-slide.is-anchor:hover .ns-slider__part--pict:after,
  #view .ns-slider--blog .ns-slide.is-anchor:focus .ns-slider__part--pict:after {
    opacity: 0.3;
  }
}
.ns-slider--blog .ns-slider__part--link {
  position: absolute;
  width: 100%;
  left: 50%;
  flex-direction: column;
  display: flex;
  align-items: center;
  top: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
  --s-btn-border: #F4F4F4;
  --s-btn-bg: transparent;
  --s-btn-bg-hov: #F4F4F4;
  --s-btn-txt: #F4F4F4;
  --s-btn-txt-hov: #232935;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-slider--blog .ns-slider__part--link {
    opacity: 0;
    pointer-events: none;
  }
}
.ns-slider--blog .ns-slider__part--link .ns-sliderLink {
  float: left;
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .ns-slider--blog .ns-slider__part--link .ns-sliderLink:hover,
  .ns-slider--blog .ns-slider__part--link .ns-sliderLink:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .ns-slider--blog .ns-slider__part--link .ns-sliderLink:active {
    background-color: var(--s-btn-bg-hov);
  }
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-slider--blog .ns-slide.is-anchor:hover .ns-slider__part--link,
  #view .ns-slider--blog .ns-slide.is-anchor:focus .ns-slider__part--link {
    opacity: 1;
  }
}
.ns-slider--blog .ns-slider__part--sliderIcon {
  max-width: 200px;
  margin-top: 0;
}
.ns-slider--blog .ns-slider__part--sliderTitle,
.ns-slider--blog .ns-slider__part--sliderLead {
  text-align: center;
  max-width: 800px;
}
.ns-slider--blog .ns-sliderControl--prev {
  left: -25px;
}
@media (min-width: 1279px) {
  .ns-slider--blog .ns-sliderControl--prev {
    left: -50px;
  }
}
.ns-slider--blog .ns-sliderControl--next {
  right: -25px;
}
@media (min-width: 1279px) {
  .ns-slider--blog .ns-sliderControl--next {
    right: -50px;
  }
}
.ns-teaserWrapper--blogTeaser {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: calc(var(--spaceTotal) * 3);
}
@media (max-width: 767px) {
  .ns-teaserWrapper--blogTeaser {
    margin-bottom: calc(var(--spaceTotal) * 1);
  }
}
.ns-teaserWrapper--blogTeaser .ns-teaserArea {
  float: left;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 80px 20px;
}
@media (max-width: 1279px) {
  .ns-teaserWrapper--blogTeaser .ns-teaserArea {
    grid-template-columns: 1fr 1fr;
    gap: 40px 20px;
  }
}
@media (max-width: 767px) {
  .ns-teaserWrapper--blogTeaser .ns-teaserArea {
    grid-template-columns: 1fr;
  }
}
.ns-teaserWrapper--blogTeaser .ns-slider__section {
  float: left;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.ns-teaserWrapper--blogTeaser .ns-slider__section:first-child {
  margin-top: 0;
}
.ns-teaserWrapper--blogTeaser .ns-slider__cover {
  float: left;
  width: 100%;
  position: relative;
}
.ns-teaserWrapper--blogTeaser .ns-slider__stage {
  float: left;
  width: 100%;
  overflow: hidden;
}
.ns-teaserWrapper--blogTeaser .ns-slider__area {
  float: left;
  width: calc(100% + 20px);
  margin-left: -10px;
  display: flex;
}
.ns-teaserWrapper--blogTeaser .ns-slide {
  float: left;
  width: calc((100%/3) - 20px);
  margin-left: 10px;
  margin-right: 10px;
  flex-shrink: 0;
  transition: margin 0.6s;
  padding-top: 30px;
  box-sizing: border-box;
}
@media (max-width: 1279px) {
  .ns-teaserWrapper--blogTeaser .ns-slide {
    width: calc((100%/2) - 20px);
  }
}
@media (max-width: 767px) {
  .ns-teaserWrapper--blogTeaser .ns-slide {
    width: calc((100%) - 20px);
  }
}
.ns-teaserWrapper--blogTeaser .ns-slide__content {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.ns-teaserWrapper--blogTeaser .ns-slide__section {
  float: left;
  position: relative;
  width: 100%;
}
.ns-teaserWrapper--blogTeaser .ns-slide__section--text {
  margin-top: calc(var(--spacePart) * 1);
}
#view .ns-teaserWrapper--blogTeaser .ns-slide__section--text {
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-teaserWrapper--blogTeaser .ns-slide__section--text {
    transform: translateY(-100%);
    opacity: 0;
  }
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-teaserWrapper--blogTeaser .ns-teaser.is-linkAround:hover .ns-slide__section--text,
  #view .ns-teaserWrapper--blogTeaser .ns-teaser.is-linkAround:focus .ns-slide__section--text {
    transform: translateY(0);
    opacity: 1;
  }
}
.ns-teaserWrapper--blogTeaser .ns-slider__part {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.ns-teaserWrapper--blogTeaser .ns-slider__part--text {
  color: var(--s-textcolor);
}
.ns-teaserWrapper--blogTeaser .ns-slider__part--pict {
  aspect-ratio: 1;
  margin: 0;
  position: relative;
  z-index: 2;
}
@supports not (aspect-ratio: 1 /  1) {
  .ns-teaserWrapper--blogTeaser .ns-slider__part--pict:before {
    float: left;
    padding-top: 100%;
    content: '';
  }
  .ns-teaserWrapper--blogTeaser .ns-slider__part--pict:after {
    display: block;
    content: '';
    clear: both;
  }
}
.ns-teaserWrapper--blogTeaser .ns-slider__part--pict * {
  height: 100%;
}
.ns-teaserWrapper--blogTeaser .ns-slider__part--pict img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#view .ns-teaserWrapper--blogTeaser .ns-slider__part--pict:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 2;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-teaserWrapper--blogTeaser .ns-teaser.is-linkAround:hover .ns-slider__part--pict:after,
  #view .ns-teaserWrapper--blogTeaser .ns-teaser.is-linkAround:focus .ns-slider__part--pict:after {
    opacity: 0.3;
  }
}
.ns-teaserWrapper--blogTeaser .ns-slider__part--link {
  position: absolute;
  width: 100%;
  left: 50%;
  flex-direction: column;
  display: flex;
  align-items: center;
  top: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
  --s-btn-border: #F4F4F4;
  --s-btn-bg: transparent;
  --s-btn-bg-hov: #F4F4F4;
  --s-btn-txt: #F4F4F4;
  --s-btn-txt-hov: #232935;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-teaserWrapper--blogTeaser .ns-slider__part--link {
    opacity: 0;
    pointer-events: none;
  }
}
.ns-teaserWrapper--blogTeaser .ns-slider__part--link .ns-sliderLink {
  float: left;
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .ns-teaserWrapper--blogTeaser .ns-slider__part--link .ns-sliderLink:hover,
  .ns-teaserWrapper--blogTeaser .ns-slider__part--link .ns-sliderLink:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .ns-teaserWrapper--blogTeaser .ns-slider__part--link .ns-sliderLink:active {
    background-color: var(--s-btn-bg-hov);
  }
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-teaserWrapper--blogTeaser .ns-teaser.is-linkAround:hover .ns-slider__part--link,
  #view .ns-teaserWrapper--blogTeaser .ns-teaser.is-linkAround:focus .ns-slider__part--link {
    opacity: 1;
  }
}
.ns-teaserWrapper--blogTeaser .ns-slider__part--sliderIcon {
  max-width: 200px;
  margin-top: 0;
}
.ns-teaserWrapper--blogTeaser .ns-slider__part--sliderTitle,
.ns-teaserWrapper--blogTeaser .ns-slider__part--sliderLead {
  text-align: center;
  max-width: 800px;
}
.ns-teaserWrapper--blogTeaser .ns-sliderControl--prev {
  left: -25px;
}
@media (min-width: 1279px) {
  .ns-teaserWrapper--blogTeaser .ns-sliderControl--prev {
    left: -50px;
  }
}
.ns-teaserWrapper--blogTeaser .ns-sliderControl--next {
  right: -25px;
}
@media (min-width: 1279px) {
  .ns-teaserWrapper--blogTeaser .ns-sliderControl--next {
    right: -50px;
  }
}
.ns-teaserWrapper--blogTeaser .ns-actions {
  margin-top: calc(var(--spaceTotal) * 1);
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ns-teaserWrapper--blogTeaser .ns-actions__link {
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .ns-teaserWrapper--blogTeaser .ns-actions__link:hover,
  .ns-teaserWrapper--blogTeaser .ns-actions__link:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .ns-teaserWrapper--blogTeaser .ns-actions__link:active {
    background-color: var(--s-btn-bg-hov);
  }
}
.ns-slider--TestimonialSlider {
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 2);
}
@media (max-width: 767px) {
  .ns-slider--TestimonialSlider {
    margin-top: calc(var(--spaceTotal) * 1);
    margin-bottom: calc(var(--spaceTotal) * 1);
  }
}
.ns-slider--TestimonialSlider .ns-slider__section--content {
  padding: 0 30px;
  box-sizing: border-box;
}
@media (min-width: 1279px) {
  .ns-slider--TestimonialSlider .ns-slider__section--content {
    padding: 0 13.63636364%;
  }
}
.ns-slider--TestimonialSlider .ns-slider__section {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.ns-slider--TestimonialSlider .ns-slider__section:first-child {
  margin-top: 0;
}
.ns-slider--TestimonialSlider .ns-slider__cover {
  float: left;
  width: 100%;
  position: relative;
}
.ns-slider--TestimonialSlider .ns-slider__stage {
  float: left;
  width: 100%;
  overflow: hidden;
}
.ns-slider--TestimonialSlider .ns-slider__area {
  float: left;
  width: calc(100% + 30px);
  margin-left: -15px;
  display: flex;
}
.ns-slider--TestimonialSlider .ns-slide {
  float: left;
  width: calc((100%) - 30px);
  margin-left: 15px;
  margin-right: 15px;
  flex-shrink: 0;
  transition: margin 0.6s cubic-bezier(0.65, 0, 0.35, 1);
  padding-top: 30px;
  box-sizing: border-box;
}
@media (max-width: 767px) {
  .ns-slider--TestimonialSlider .ns-slide {
    width: calc((100%) - 30px);
  }
}
.ns-slider--TestimonialSlider .ns-slide__content {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 160px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
@media (max-width: 1279px) {
  .ns-slider--TestimonialSlider .ns-slide__content {
    gap: 50px;
  }
}
@media (max-width: 767px) {
  .ns-slider--TestimonialSlider .ns-slide__content {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 20px;
  }
}
.ns-slider--TestimonialSlider .ns-slide__section {
  float: left;
  width: 50%;
  transform: translateY(30px);
  opacity: 0;
  transition: all 0.8s ease;
  transition-delay: 0.5s;
}
@media (max-width: 767px) {
  .ns-slider--TestimonialSlider .ns-slide__section {
    width: 100%;
  }
}
.ns-slider--TestimonialSlider .ns-slide.is-active .ns-slide__section {
  transform: translateY(0);
  opacity: 1;
}
.ns-slider--TestimonialSlider .ns-slide__section--quote {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  .ns-slider--TestimonialSlider .ns-slide__section--person {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
  .ns-slider--TestimonialSlider .ns-slide__section--person > div {
    width: 50%;
  }
}
.ns-slider--TestimonialSlider .ns-slider__part {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.ns-slider__part--text {
  color: var(--s-textcolor);
}
.ns-slider__part--text .clientName {
  font-weight: 500;
}
.ns-slider--TestimonialSlider .ns-slider__part--link {
  padding-top: 20px;
}
.ns-slider--TestimonialSlider .ns-slider__part--link .open {
  float: left;
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .ns-slider--TestimonialSlider .ns-slider__part--link .open:hover,
  .ns-slider--TestimonialSlider .ns-slider__part--link .open:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .ns-slider--TestimonialSlider .ns-slider__part--link .open:active {
    background-color: var(--s-btn-bg-hov);
  }
}
#view .ns-slider--TestimonialSlider .ns-slider__part--link .open.undefined {
  display: none;
}
.ns-slider--TestimonialSlider .ns-slider__part--sliderIcon {
  max-width: 200px;
  margin-top: 0;
}
.ns-slider--TestimonialSlider .ns-slider__part--sliderTitle,
.ns-slider--TestimonialSlider .ns-slider__part--sliderLead {
  text-align: center;
  max-width: 800px;
}
.ns-teaserSlider {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: calc(var(--spaceTotal) * 3);
}
@media (max-width: 767px) {
  .ns-teaserSlider {
    margin-bottom: calc(var(--spaceTotal) * 1);
  }
}
.ns-slider--project .ns-slider__section {
  float: left;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.ns-slider--project .ns-slider__section:first-child {
  margin-top: 0;
}
.ns-slider--project .ns-slider__cover {
  float: left;
  width: 100%;
  position: relative;
}
.ns-slider--project .ns-slider__stage {
  float: left;
  width: 100%;
  overflow: hidden;
}
.ns-slider--project .ns-slider__area {
  float: left;
  width: calc(100% + 20px);
  margin-left: -10px;
  display: flex;
}
.ns-slider--project .ns-slide {
  float: left;
  width: calc((100%/3) - 20px);
  margin-left: 10px;
  margin-right: 10px;
  flex-shrink: 0;
  transition: margin 0.6s;
  padding-top: 30px;
  box-sizing: border-box;
}
@media (max-width: 1279px) {
  .ns-slider--project .ns-slide {
    width: calc((100%/2) - 20px);
  }
}
@media (max-width: 767px) {
  .ns-slider--project .ns-slide {
    width: calc((100%) - 20px);
  }
}
.ns-slider--project .ns-slide__content {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.ns-slider--project .ns-slide__section {
  float: left;
  position: relative;
  width: 100%;
}
.ns-slider--project .ns-slide__section--text {
  margin-top: calc(var(--spacePart) * 1);
}
#view .ns-slider--project .ns-slide__section--text {
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-slider--project .ns-slide__section--text {
    transform: translateY(-100%);
    opacity: 0;
  }
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-slider--project .ns-slide.is-anchor:hover .ns-slide__section--text,
  #view .ns-slider--project .ns-slide.is-anchor:focus .ns-slide__section--text {
    transform: translateY(0);
    opacity: 1;
  }
}
.ns-slider--project .ns-slider__part {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.ns-slider__part--text {
  color: var(--s-textcolor);
}
.ns-slider--project .ns-slider__part--pict {
  aspect-ratio: 1;
  margin: 0;
  position: relative;
  z-index: 2;
}
@supports not (aspect-ratio: 1 /  1) {
  .ns-slider--project .ns-slider__part--pict:before {
    float: left;
    padding-top: 100%;
    content: '';
  }
  .ns-slider--project .ns-slider__part--pict:after {
    display: block;
    content: '';
    clear: both;
  }
}
.ns-slider--project .ns-slider__part--pict * {
  height: 100%;
}
.ns-slider--project .ns-slider__part--pict img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#view .ns-slider--project .ns-slider__part--pict:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 2;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-slider--project .ns-slide.is-anchor:hover .ns-slider__part--pict:after,
  #view .ns-slider--project .ns-slide.is-anchor:focus .ns-slider__part--pict:after {
    opacity: 0.3;
  }
}
.ns-slider--project .ns-slider__part--link {
  position: absolute;
  width: 100%;
  left: 50%;
  flex-direction: column;
  display: flex;
  align-items: center;
  top: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
  --s-btn-border: #F4F4F4;
  --s-btn-bg: transparent;
  --s-btn-bg-hov: #F4F4F4;
  --s-btn-txt: #F4F4F4;
  --s-btn-txt-hov: #232935;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-slider--project .ns-slider__part--link {
    opacity: 0;
    pointer-events: none;
  }
}
.ns-slider--project .ns-slider__part--link .ns-sliderLink {
  float: left;
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .ns-slider--project .ns-slider__part--link .ns-sliderLink:hover,
  .ns-slider--project .ns-slider__part--link .ns-sliderLink:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .ns-slider--project .ns-slider__part--link .ns-sliderLink:active {
    background-color: var(--s-btn-bg-hov);
  }
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-slider--project .ns-slide.is-anchor:hover .ns-slider__part--link,
  #view .ns-slider--project .ns-slide.is-anchor:focus .ns-slider__part--link {
    opacity: 1;
  }
}
.ns-slider--project .ns-slider__part--sliderIcon {
  max-width: 200px;
  margin-top: 0;
}
.ns-slider--project .ns-slider__part--sliderTitle,
.ns-slider--project .ns-slider__part--sliderLead {
  text-align: center;
  max-width: 800px;
}
.ns-slider--project .ns-sliderControl--prev {
  left: -25px;
}
@media (min-width: 1279px) {
  .ns-slider--project .ns-sliderControl--prev {
    left: -50px;
  }
}
.ns-slider--project .ns-sliderControl--next {
  right: -25px;
}
@media (min-width: 1279px) {
  .ns-slider--project .ns-sliderControl--next {
    right: -50px;
  }
}
.ns-teaserWrapper--projectTeaser {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: calc(var(--spaceTotal) * 3);
}
@media (max-width: 767px) {
  .ns-teaserWrapper--projectTeaser {
    margin-bottom: calc(var(--spaceTotal) * 1);
  }
}
.ns-teaserWrapper--projectTeaser .ns-teaserArea {
  float: left;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: calc(var(--spaceUnit) * 2);
}
.ns-teaserWrapper--projectTeaser .ns-slider__section {
  float: left;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.ns-teaserWrapper--projectTeaser .ns-slider__section:first-child {
  margin-top: 0;
}
.ns-teaserWrapper--projectTeaser .ns-slider__cover {
  float: left;
  width: 100%;
  position: relative;
}
.ns-teaserWrapper--projectTeaser .ns-slider__stage {
  float: left;
  width: 100%;
  overflow: hidden;
}
.ns-teaserWrapper--projectTeaser .ns-slider__area {
  float: left;
  width: calc(100% + 20px);
  margin-left: -10px;
  display: flex;
}
.ns-teaserWrapper--projectTeaser .ns-slide {
  float: left;
  width: calc((100%/3) - 20px);
  margin-left: 10px;
  margin-right: 10px;
  flex-shrink: 0;
  transition: margin 0.6s;
  padding-top: 30px;
  box-sizing: border-box;
}
@media (max-width: 1279px) {
  .ns-teaserWrapper--projectTeaser .ns-slide {
    width: calc((100%/2) - 20px);
  }
}
@media (max-width: 767px) {
  .ns-teaserWrapper--projectTeaser .ns-slide {
    width: calc((100%) - 20px);
  }
}
.ns-teaserWrapper--projectTeaser .ns-slide__content {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.ns-teaserWrapper--projectTeaser .ns-slide__section {
  float: left;
  position: relative;
  width: 100%;
}
.ns-teaserWrapper--projectTeaser .ns-slider__part {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.ns-teaserWrapper--projectTeaser .ns-slider__part--text {
  color: var(--s-textcolor);
  font-size: 24px;
  line-height: 1.25;
}
@media (max-width: 1279px) {
  .ns-teaserWrapper--projectTeaser .ns-slider__part--text {
    font-size: 22px;
    line-height: 1.45454545;
  }
}
.ns-teaserWrapper--projectTeaser .ns-slider__part--textBold {
  font-weight: bold;
}
.ns-teaserWrapper--projectTeaser .ns-slider__part--pict {
  position: relative;
  isolation: isolate;
  aspect-ratio: 1.78378378;
}
@supports not (aspect-ratio: 1320 /  740) {
  .ns-teaserWrapper--projectTeaser .ns-slider__part--pict:before {
    float: left;
    padding-top: 56.06060606%;
    content: '';
  }
  .ns-teaserWrapper--projectTeaser .ns-slider__part--pict:after {
    display: block;
    content: '';
    clear: both;
  }
}
.ns-teaserWrapper--projectTeaser .ns-slider__part--pict * {
  height: 100%;
}
.ns-teaserWrapper--projectTeaser .ns-slider__part--pict img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.ns-teaserWrapper--projectTeaser .ns-slider__part--pict:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
  z-index: 2;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
#view .ns-teaserWrapper--projectTeaser .ns-slider__part--pict:after {
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  opacity: 0;
}
@media (max-width: 1279px) {
  .ns-teaserWrapper--projectTeaser .ns-slider__part--pict {
    width: 100vw;
    margin-left: calc(var(--deskSpace) * -1);
  }
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-teaserWrapper--projectTeaser .ns-teaser.is-linkAround:hover .ns-slider__part--pict:after,
  #view .ns-teaserWrapper--projectTeaser .ns-teaser.is-linkAround:focus .ns-slider__part--pict:after {
    opacity: 0.3;
  }
}
.ns-teaserWrapper--projectTeaser .ns-slider__part--link {
  position: absolute;
  width: 100%;
  left: 50%;
  flex-direction: column;
  display: flex;
  align-items: center;
  top: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
  --s-btn-border: #F4F4F4;
  --s-btn-bg: transparent;
  --s-btn-bg-hov: #F4F4F4;
  --s-btn-txt: #F4F4F4;
  --s-btn-txt-hov: #232935;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
.ns-teaserWrapper--projectTeaser .ns-slider__part--link .ns-sliderLink {
  float: left;
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .ns-teaserWrapper--projectTeaser .ns-slider__part--link .ns-sliderLink:hover,
  .ns-teaserWrapper--projectTeaser .ns-slider__part--link .ns-sliderLink:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .ns-teaserWrapper--projectTeaser .ns-slider__part--link .ns-sliderLink:active {
    background-color: var(--s-btn-bg-hov);
  }
}
@media (max-width: 1279px) {
  #view .ns-teaserWrapper--projectTeaser .ns-slider__part--link {
    display: none;
  }
}
.ns-teaserWrapper--projectTeaser .ns-slider__part--sliderIcon {
  max-width: 200px;
  margin-top: 0;
}
.ns-teaserWrapper--projectTeaser .ns-slider__part--title {
  --s-textcolor: #F4F4F4;
  position: absolute;
  top: 180px;
  z-index: 12;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
}
@media (max-width: 1279px) {
  .ns-teaserWrapper--projectTeaser .ns-slider__part--title {
    top: 50px;
  }
}
@media (max-width: 767px) {
  .ns-teaserWrapper--projectTeaser .ns-slider__part--title {
    top: 30px;
  }
}
@media (max-width: 1279px) {
  #view .ns-teaserWrapper--projectTeaser .ns-slider__part--title {
    display: none;
  }
}
#view .ns-teaserWrapper--projectTeaser .ns-slide__overlay {
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  pointer-events: none;
}
@media (hover: hover) and (pointer: fine) {
  #view .ns-teaserWrapper--projectTeaser .ns-teaser.is-linkAround:hover .ns-slide__overlay,
  #view .ns-teaserWrapper--projectTeaser .ns-teaser.is-linkAround:focus .ns-slide__overlay {
    opacity: 1;
  }
}
.ns-teaserWrapper--projectTeaser .ns-sliderControl--prev {
  left: -25px;
}
@media (min-width: 1279px) {
  .ns-teaserWrapper--projectTeaser .ns-sliderControl--prev {
    left: -50px;
  }
}
.ns-teaserWrapper--projectTeaser .ns-sliderControl--next {
  right: -25px;
}
@media (min-width: 1279px) {
  .ns-teaserWrapper--projectTeaser .ns-sliderControl--next {
    right: -50px;
  }
}
.ns-teaserWrapper--projectTeaser .ns-actions {
  margin-top: calc(var(--spaceTotal) * 1);
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ns-teaserWrapper--projectTeaser .ns-actions__link {
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .ns-teaserWrapper--projectTeaser .ns-actions__link:hover,
  .ns-teaserWrapper--projectTeaser .ns-actions__link:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .ns-teaserWrapper--projectTeaser .ns-actions__link:active {
    background-color: var(--s-btn-bg-hov);
  }
}
.ns-filter {
  float: left;
  width: 100%;
  position: relative;
  z-index: 20;
  margin-top: calc(var(--spacePart) * 1);
}
.ns-dropdown {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
}
.ns-dropdown__content {
  float: left;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
}
.ns-dropdown__toggle {
  display: none;
}
.ns-dropdown__item {
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .ns-dropdown__item:hover,
  .ns-dropdown__item:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .ns-dropdown__item:active {
    background-color: var(--s-btn-bg-hov);
  }
}
.ns-dropdown__item.is-active {
  color: var(--s-btn-txt-hov);
  background-color: var(--s-btn-bg-hov);
}
@media (max-width: 1279px) {
  .ns-dropdown {
    align-items: flex-start;
  }
  .ns-dropdown__toggle {
    display: block;
    border: 3px solid var(--s-btn-border);
    background-color: var(--s-btn-bg);
    line-height: 1.3;
    color: var(--s-btn-txt);
    padding: 5px 20px;
    -webkit-appearance: none;
    border-radius: 0;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-weight: 500;
    font-size: 24px;
    line-height: 1;
    padding-right: 49px;
    font-family: 'brandon-grotesque', sans-serif;
    cursor: pointer;
    background-size: 16px 8px;
    background-position: calc(100% - 10px) 50%;
    background-repeat: no-repeat;
    background-image: url(/images/icon-arrow-greyblue.svg);
  }
  .ns-dropdown__content {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 2;
    background-color: #F4F4F4;
    max-height: 0;
    overflow-y: auto;
    display: unset;
    width: 100vw;
    padding: 0 calc(var(--deskSpace) * 1);
    margin-left: calc(var(--deskSpace) * -1);
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    margin-bottom: calc(var(--spacePart) * 1);
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .ns-dropdown__content::-webkit-scrollbar {
    display: none;
  }
  .is-open .ns-dropdown__content {
    max-height: initial;
  }
  .ns-unitFilter__list {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: calc(var(--spacePart) * 1);
  }
  .ns-unitFilter__actions {
    column-gap: 30px;
    margin-top: calc(var(--spaceTotal) * 1);
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    display: none;
    flex-direction: row;
    justify-content: space-between;
  }
  .ns-unitFilter__link {
    margin-right: 0;
    margin-bottom: 0;
  }
  .ns-unitFilter__group:first-child {
    border-top: none;
  }
  .ns-unitFilter__group:last-child {
    border-bottom: none;
  }
  .ns-unitFilter__groupToggle {
    padding-left: 40px;
    padding-right: 60px;
  }
  .ns-unitFilter__groupToggle:after {
    right: 40px;
  }
  .ns-unitFilter__groupContent {
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
  }
  .ns-dropdown__item {
    margin-right: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 1279px) and (hover: hover) and (pointer: fine) {
  .ns-dropdown__toggle:hover,
  .ns-dropdown__toggle:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .ns-dropdown__toggle:active {
    background-color: var(--s-btn-bg-hov);
  }
}
@media (max-width: 1279px) and (hover: hover) and (pointer: fine) {
  .ns-dropdown__toggle:hover,
  .ns-dropdown__toggle:focus {
    background-image: url(/images/icon-arrow-white.svg);
  }
}
/*# sourceMappingURL=./screen-small.css.map */